import createSmartIntersector from "../../utils/createSmartIntersector";
import loadExternalScript from "../../utils/loadExternalScript";
import * as mediaPool from "./_mediaPool";

function makeScriptUrl(account, player) {
    return `https://players.brightcove.net/${account}/${player}_default/index.min.js`;
}

function onIntersect(entry) {
    const { target } = entry;
    const { account, player } = target.dataset;

    const scriptUrl = makeScriptUrl(account, player);

    function onFreeSlot(resourceID) {
        function release() {
            mediaPool.release(resourceID);
        }

        function onScriptLoad() {
            const player = bc(target);
            player.on("loadedmetadata", release);
        }

        loadExternalScript(scriptUrl, onScriptLoad);
    }

    mediaPool.request(1500).then(onFreeSlot);
}

export default function lazyBrightcove(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        initialThreshold: 500,
        dependencySrc: "https://embed.redditmedia.com/widgets/platform.js",
        selector: ".rm-embed-brightcove video-js",
    });
}
