import elementChangeListener from "../elementChangeListener";

function addTask(task) {
    task._reportedIntersections = task._reportedIntersections || 0;
    task.threshold = task.threshold || 0;
    task.finished = task.finished || new Set([]);

    function handleNonIntersection(entry) {
        if (task.onNonIntersect) {
            task.onNonIntersect(entry);
        }
        const { target } = entry;

        if (task.onBellowViewport || task.onAboveViewport) {
            const { top } = target.getBoundingClientRect();
            if (top < 0 && task.onAboveViewport) {
                task.onAboveViewport(target);
            }

            if (top >= 0 && task.onBellowViewport) {
                task.onBellowViewport(target);
            }
        }
    }

    function reportIntersection(entry) {
        const { target } = entry;

        if (!entry.isIntersecting) {
            handleNonIntersection(entry);
            return null;
        }

        if (task.firstOnly && task._reportedIntersections > 0) {
            return null;
        }

        task._reportedIntersections++;

        if (task.firstOnly) {
            task.observer.disconnect();
            task.mutationObserver.disconnect();
        } else if (task.oneTime) {
            task.observer.unobserve(target);
            task.finished.add(target);
        }

        if (task.onIntersect) {
            task.onIntersect(entry);
        }
    }

    function entriesHandler(entries) {
        entries.forEach(reportIntersection);
    }

    const rootMargin = task.rootMargin || `0px 0px ${task.threshold}px 0px`;
    task.observer = new IntersectionObserver(task.entriesHandler || entriesHandler, {
        rootMargin,
    });

    if (task.selector) {
        task.mutationObserver = elementChangeListener(task.selector, (node) => {
            if (!task.finished.has(node)) {
                task.onAppear && task.onAppear(node);
                task.observer.observe(node);
            }
        });
    }

    if (task.rawNodes) {
        task.rawNodes.forEach((element) => {
            task.observer.observe(element);
        });
    }

    return [task.observer, task.mutationObserver];
}

export default {
    addTask,
};
