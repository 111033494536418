import getDevice from "../utils/getDevice";
import loadContextData from "../utils/loadContextData";
import { initClickHandler } from "../utils/initClickHandler";
import htmlDecode from "../utils/htmlDecode";
import * as postStatus from "../utils/postStatus";
import createElementChangeListener from "../elementChangeListener";
import createSmartIntersector from "../utils/createSmartIntersector";
import rebelSlider from "../lib/sliderLoader";
import loadExternalScript from "../utils/loadExternalScript";
import loadExternalStylesheet from "../utils/loadExternalStylesheet";
import loadFontFace from "../utils/loadFontFace";
import { showFlexibleSticky } from "../views/doubleStickyLoader";
import showInformer from "../views/informer/loader";
import getRenderer from "../renderer/loadRenderer";
import fullBootstrap from "../transport/fullBootstrap";
import partial from "../lib/core/functional/partial";
import raiseNotification from "../utils/raiseNotification";
import { spawnTask, runAsTasks } from "../utils/spawnTask.js";

export default function initStdLib(context) {
    window.REBELMOUSE_STDLIB = {
        context,
        getDevice,
        loadContextData,
        initClickHandler,
        htmlDecode,
        createElementChangeListener,
        spawnTask,
        runAsTasks,
        createSmartIntersector: partial(
            createSmartIntersector,
            context.intersectionObserverProvider
        ),
        rebelSlider,
        loadExternalScript,
        loadExternalStylesheet,
        loadFontFace,
        getRenderer,
        postStatus,
        raiseNotification,
        onElementInViewport: context.intersectionObserverProvider.addTask,
        widgetControl: {
            doubleSticky: { showFlexibleSticky },
        },
        showInformer: (config) => {
            showInformer(context, config);
        },
        getBootstrap: () => fullBootstrap(context),
    };
    return context;
}
