import { runAsTasks } from "./spawnTask.js";

export default function createCallbackRunner(boot, autoTriggerAfterRun) {
    const callbacks = [];
    let ran = false;

    function add(callback) {
        if (autoTriggerAfterRun && ran) {
            callback();
            return;
        }

        callbacks.push(callback);
        if (callbacks.length == 1) {
            boot();
        }
    }

    function onceRan() {
        callbacks.length = 0;
    }

    function run() {
        ran = true;
        runAsTasks(...callbacks).then(onceRan);
    }

    function hasRun() {
        return ran;
    }

    return { add, run, hasRun };
}
