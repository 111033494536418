import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";

function onIntersect(entry) {
    function onFreeSlot(resourceID) {
        function onLoad() {
            mediaPool.release(resourceID);
        }
        const node = entry.target;
        window.FB.XFBML.parse(node.parentElement, onLoad);
    }

    mediaPool.request(1500).then(onFreeSlot);
}

function onDependencyLoad() {
    const bootstrap = window.REBELMOUSE_BOOTSTRAP_DATA;
    window.FB.init({
        appId: bootstrap.fbId,
        version: bootstrap.fbAppVersion,
        status: true,
        cookie: true,
    });
}

export default function loadLazyFacebook(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        onDependencyLoad,
        initialThreshold: 500,
        selector: ".fb-post, .fb-page, .fb-video, .fb-comments, .fb-like",
        dependencySrc: "https://connect.facebook.net/en_US/sdk.js",
    });
}
