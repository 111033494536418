import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";

function onIntersect(entry) {
    function onFreeSlot() {
        const embed = entry.target;
        const container = embed.closest(".rm-embed-container.rm-loading");
        if (container) {
            container.classList.remove("rm-loading");
            container.classList.add("rm-loaded");
        }

        if (!embed.dataset.id) {
            embed.classList.add("imgur-embed-pub");
            embed.dataset.id = embed.dataset.lazyId;
        }

        if ("createIframe" in window.imgurEmbed) {
            //Their library works in a weird way, while at this point of the script
            //we've already loaded the imgurEmbed library, the createIframe
            //function is added later, what's why we need this if statement
            window.imgurEmbed.createIframe(embed.parentElement);
        }
    }

    mediaPool.request(2000).then(onFreeSlot);
}

function invalidate(element) {
    element.classList.remove("imgur-embed-pub");
    element.dataset.lazyId = element.dataset.id;
    delete element.dataset.id;
}

function beforeDependencyLoad() {
    document.querySelectorAll("blockquote.imgur-custom-embed-pub").forEach(invalidate);
}

export default function loadLazyImgur(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        beforeDependencyLoad,
        selector: "blockquote.imgur-custom-embed-pub",
        dependencySrc: "https://s.imgur.com/min/embed.js",
        initialThreshold: 400,
    });
}
