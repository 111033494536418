import onDomReady from "./index";

var neededPolyfills = 0;

function loadApp() {
    if (document.readyState !== "loading") {
        onDomReady();
    } else {
        window.addEventListener("DOMContentLoaded", onDomReady);
    }
}

function onPolyfillLoad() {
    neededPolyfills--;
    if (neededPolyfills === 0) {
        loadApp();
    }
}

function existsOrPolyfill(api, file) {
    if (api) {
        return;
    }
    neededPolyfills++;
    var script = document.createElement("script");
    script.src = `${window.REBELMOUSE_BASE_SSL_DOMAIN}/static/polyfills/${file}.js`;
    script.addEventListener("load", onPolyfillLoad);
    document.head.appendChild(script);
}

existsOrPolyfill(window.requestAnimationFrame, "requestAnimationFrame");
existsOrPolyfill(window.MutationObserver, "mutationobserver-polyfill");
existsOrPolyfill(window.IntersectionObserver, "intersection-observer");
existsOrPolyfill(typeof URL === "function", "url-polyfill");
existsOrPolyfill(window.URLSearchParams, "urlsearchparams-polyfill");
existsOrPolyfill(window.Array.from, "arrayfrom-polyfill");
existsOrPolyfill(window.Array.prototype.find, "find-polyfill");
existsOrPolyfill(Element.prototype.replaceWith, "replace-with-polyfill");
existsOrPolyfill("content" in document.createElement("template"), "template-v2");
existsOrPolyfill(window.NodeList.prototype.forEach, "polyfill-ie11-nodelist-foreach");
existsOrPolyfill(typeof window.CustomEvent === "function", "event-polyfill");
existsOrPolyfill(Element.prototype.closest && Element.prototype.matches, "closest-polyfill");
existsOrPolyfill(Object.values, "object-values-polyfill");
existsOrPolyfill(Object.entries, "entries-polyfill");
existsOrPolyfill(Object.fromEntries, "fromEntries-polyfill");
existsOrPolyfill(Element.prototype.remove, "remove-polyfill");
existsOrPolyfill("classList" in Element.prototype, "classList-polyfill");
existsOrPolyfill(window.Promise, "promise-polyfill");
existsOrPolyfill(window.fetch, "fetch-polyfill-v3");
existsOrPolyfill(Array.prototype.flatMap, "flatMap-polyfill");
existsOrPolyfill(Array.prototype.includes, "includes-polyfills");

if (neededPolyfills === 0) {
    loadApp();
}
