import loadModuleOnElement from "../utils/loadModuleOnElement";

const selector = ".rm-top-sticky";

const loader = () => import("./doubleSticky");

export function showFlexibleSticky(stickyElement) {
    loader().then(function onModule(module) {
        module.showFlexibleSticky(stickyElement);
    });
}

export default function doubleStickyLoader(context) {
    loadModuleOnElement(selector, loader, context);
    return context;
}
