import createQueue from "../../utils/simpleQueue";
import { listenToConnectionChanges } from "../../utils/connectionHandler";

const queue = createQueue(9);

const connectionMap = {
    "4g": () => queue.changeSettings(15),
    "slow-2g": () => queue.changeSettings(3),
    "2g": () => queue.changeSettings(3),
    "3g": () => queue.changeSettings(6),
};

listenToConnectionChanges(function onConnectionInfoChanged(effectiveType) {
    (connectionMap[effectiveType] || connectionMap["4g"])();
});

queue.onEmpty(function onMediaQueueEmpty() {
    const event = new CustomEvent("mediaqueue:finished");
    window.dispatchEvent(event);
});

export const release = queue.release;
export const request = queue.request;
