import callbackRunner from "./callbackRunner";

const { add, run } = callbackRunner(boot);

function boot() {
    window.addEventListener("scroll", onScroll, { passive: true });
}

function onScroll() {
    window.removeEventListener("scroll", onScroll, { passive: true });
    run();
}

export default function loadModuleOnScroll(callback) {
    add(callback);
}
