import curry from "../lib/core/functional/curry";

const fieldUpdater = curry(function fieldUpdater(selector, elementField, dataField, data) {
    if (!data[dataField]) {
        return;
    }

    const element = document.querySelector(selector);
    if (element) {
        element[elementField] = data[dataField];
    }
});

const updateTitle = fieldUpdater("title", "textContent", "headline");
const updateCanonical = fieldUpdater('link[rel="canonical"]', "href", "url");

export default function updateMetaData(data) {
    updateTitle(data);
    updateCanonical(data);
}
