import createElementChangeListener from "../elementChangeListener";

const MENU_OPENED_CLASS = "menu-opened";
const HAMBURGER_SELECTOR = ".rebelbar__menu-toggle";
const MENU_OVERLAYSELECTOR = ".menu-overlay";

function toggleMenu() {
    document.body.classList.toggle(MENU_OPENED_CLASS);
}

function onHamburgerToggler(el) {
    el.addEventListener("click", toggleMenu);
}

function removeMenu() {
    document.body.classList.remove(MENU_OPENED_CLASS);
}

export default function initHamburgerMenu(context) {
    createElementChangeListener(HAMBURGER_SELECTOR, onHamburgerToggler);
    const menuOverlay = document.querySelector(MENU_OVERLAYSELECTOR);
    if (menuOverlay) {
        menuOverlay.addEventListener("click", removeMenu);
    }
    return context;
}
