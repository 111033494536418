const handlersByEvent = {};

function publish(event, data) {
    const subscribers = handlersByEvent[event] || [];
    subscribers.forEach(subscriber => {
        subscriber(data);
    });
}

function subscribe(event, handler) {
    handlersByEvent[event] = handlersByEvent[event] || [];
    handlersByEvent[event].push(handler);
}

export default {
    subscribe,
    publish
};
