function onIntersect(_entry) {
    const lazyStyle = document.querySelector(`script.lazy-style`);
    if (!lazyStyle) {
        return;
    }
    const style = document.createElement("style");
    style.type = "text/css";
    style.classList.add("lazy-style");
    style.textContent = lazyStyle.textContent;
    document.head.appendChild(style);
    lazyStyle.remove();
}

export default function viewportStyle(intersectionObserverProvider) {
    intersectionObserverProvider.addTask({
        onIntersect,
        threshold: 500,
        selector: ".viewport-css-anchor",
        firstOnly: true,
    });
}
