import loadModuleOnScroll from "../utils/loadModuleOnScroll";

export default function floatingElementsLoader(context) {
    loadModuleOnScroll(() => {
        const floatingElements = document.querySelectorAll(".js-hidden-panel");
        if (floatingElements.length) {
            import("./floatingElements").then((module) => {
                module.default(floatingElements);
            });
        }
    });
    return context;
}
