function handleAsNewTask(resolve) {
    setTimeout(resolve, 0);
}

export function spawnTask() {
    return new Promise(handleAsNewTask);
}

function isInputPending() {
    if (navigator && "scheduling" in navigator && "isInputPending" in navigator.scheduling) {
        return navigator.scheduling.isInputPending();
    }

    return false;
}

export async function runAsTasks(...tasks) {
    let deadline = performance.now() + 50;

    while (tasks.length > 0) {
        if (isInputPending() || performance.now() >= deadline) {
            await spawnTask();
            deadline = performance.now() + 50;
            continue;
        }

        const task = tasks.shift();
        await task();
    }
}
