import curry from "../lib/core/functional/curry";

function sendFetch(url, options) {
    const [fetchUrl, fetchOptions] = prepareSSLRequest(url, options);
    return fetch(fetchUrl, fetchOptions).then(jsonHandler).catch(handleFetchError);
}

export function sendRawRequest(url, method, body) {
    const options = {
        method,
        body,
    };
    return sendFetch(url, options);
}

export function sendXHRRequest(url, method, body) {
    const options = {
        method,
        body,
        headers: { ...HEADER_XMLHTTPREQUEST },
    };
    return sendFetch(url, options);
}

export function sendJsonRequest(url, method, body) {
    const options = {
        method,
        body: JSON.stringify(body),
        headers: { ...HEADER_CONTENT_JSON },
    };
    return sendFetch(url, options);
}

export function retrieveJsonRequest(url, method) {
    return sendFetch(url, { method: method || "GET" });
}

const prepareSSLRequest = (url, userOptions) => {
    const options = { ...userOptions } || {};
    const baseSSLDomain = window.REBELMOUSE_BASE_SSL_DOMAIN;
    url = (baseSSLDomain || "https://" + location.host) + url;
    options.credentials = "include";
    return [url, options];
};

const HEADER_XMLHTTPREQUEST = {
    "X-Requested-With": "XMLHttpRequest",
};

const HEADER_CONTENT_JSON = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

const DEFAULT_ERROR = { message: "It wasn't possible to fulfil this request" };

function isObject(maybeObject) {
    return maybeObject && typeof maybeObject === "object";
}

const rejectEvaluators = [
    (_rawRes, res) => (res === false ? { ...DEFAULT_ERROR } : false),
    (rawRes, res) => (!rawRes.ok ? res : false),
    (_rawRes, res) => (isObject(res) && "error" in res ? { ...res, message: res.error } : false),
    (_rawRes, res) => (isObject(res) && "message" in res && "code" in res ? res : false),
    (_rawRes, res) =>
        isObject(res) && "errors" in res && res.errors && Array.isArray(res.errors.email)
            ? { ...res, message: res.errors.email[0] }
            : false,
];

const resolveEvaluators = [(_rawRes, res) => (res === true ? {} : false), (_rawRes, res) => res];

function evaluatePipeline(rawRes, res, evaluators) {
    let transformedResponse;
    evaluators.some((fun) => {
        try {
            transformedResponse = fun(rawRes, res);
        } catch {
            transformedResponse = false;
        }
        return transformedResponse;
    });
    return transformedResponse;
}

const handleJsonResponse = curry((rawResponse, response) => {
    const rejectResponse = evaluatePipeline(rawResponse, response, rejectEvaluators);
    if (rejectResponse) {
        throw rejectResponse;
    }

    return evaluatePipeline(rawResponse, response, resolveEvaluators);
});

const coerceResponseToJson = curry((rawResponse, textResponse) => {
    let readableResponse;
    try {
        readableResponse = JSON.parse(textResponse);
    } catch {
        if (rawResponse.ok) {
            return { message: textResponse };
        } else {
            return { error: textResponse };
        }
    }
    return readableResponse;
});

function jsonHandler(response) {
    return response.text().then(coerceResponseToJson(response)).then(handleJsonResponse(response));
}

const NETWORK_ERROR = { message: "There was a network error, please try again" };

function handleFetchError(error) {
    if (error instanceof TypeError) {
        throw NETWORK_ERROR;
    }

    throw error;
}
