import createElementChangeListener from "../../elementChangeListener";

function loadScript(script) {
    const node = document.createElement("script");
    const source = script.getAttribute("data-src");
    if (source) {
        node.src = source;
    } else {
        node.text = script.text;
    }
    script.replaceWith(node);
}

export default function loadLazyScripts() {
    const selector = `[type="text/lazy-javascript"]`;
    createElementChangeListener(selector, loadScript);
}
