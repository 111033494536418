import loadModuleOnScroll from "../utils/loadModuleOnScroll";

export default function changeUrlOnScrollLoader(context) {
    loadModuleOnScroll(() => {
        import("./changeUrlOnScroll").then((module) => {
            module.default(context);
        });
    });
    return context;
}
