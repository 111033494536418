import noop from "../lib/core/functional/noop.js";

import loadExternalResource from "./loadExternalResource";

function isFbHack(src) {
    try {
        const url = new URL(src);
        return url.host === "connect.facebook.net";
    } catch (error) {
        return false;
    }
}

function loadTag(src, callback, onError) {
    const selector = `script[src="${src}"]`;
    const element = document.createElement("script");
    element.src = src;
    element.async = true;
    loadExternalResource(selector, element, callback, onError || noop);
}

const BACKUP_INSTAGRAM =
    "https://s3.amazonaws.com/roar-assets-auto.rbl.ms/shared/instagram/embed.js";

export default function loadExternalScript(src, callback, onError) {
    if (isFbHack(src)) {
        function onIgReady() {
            loadTag(src, callback, onError);
        }

        loadExternalScript("https://www.instagram.com/embed.js", onIgReady);
        return;
    }

    if (src === "https://www.instagram.com/embed.js") {
        loadTag(BACKUP_INSTAGRAM, callback, noop);
        return;
    }

    loadTag(src, callback, onError);
}
