import createSmartIntersector from '../../utils/createSmartIntersector';

function onIntersect(){}

export default function loadLazyReddit(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        initialThreshold: 500,
        dependencySrc: "https://embed.redditmedia.com/widgets/platform.js",
        selector: "blockquote.reddit-card",
    });
}
