import loadModuleOnElement from "../utils/loadModuleOnElement";

const slideshowSelector = ".listicle-slideshow";
const fullscreenSlideshowSelector = ".js--fullscreen-preview";
const paginationSelector = ".js-listicle-switch-page";
const votingSelector = ".listicle-voting";

const slideshowLoader = () => import("./assemblerSlideshow");
const fullScreenSlideshowLoader = () => import("./assemblerFullScreenSlideshow");
const paginationLoader = () => import("./assemblerPagination");
const votingLoader = () => import("./assemblerVoting");

export default function assemblerSlideshowLoader(context) {
    loadModuleOnElement(slideshowSelector, slideshowLoader, context);
    loadModuleOnElement(fullscreenSlideshowSelector, fullScreenSlideshowLoader, context);
    loadModuleOnElement(paginationSelector, paginationLoader, context);
    loadModuleOnElement(votingSelector, votingLoader, context);

    return context;
}
