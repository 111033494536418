import createElementChangeListener from "../../elementChangeListener";

const v1Loader = () => import("./view");
const v2Loader = () => import("./v2");

function loadSignupBoxOnElement(context, element) {
    let loader;
    if (element.children.length) {
        loader = v2Loader;
    } else {
        loader = v1Loader;
    }
    loader().then((module) => module.default(context, element));
}

export default function loginSignupLoader(context) {
    function onElement(element) {
        loadSignupBoxOnElement(context, element);
    }

    createElementChangeListener(".login-signup-wrapper", onElement);
    return context;
}
