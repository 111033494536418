import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";
import { controlSpacer } from "./utils";

const activeTwitterRequests = [];

function releaseTweetResource(event) {
    const resourceID = activeTwitterRequests.shift();
    if (resourceID) {
        mediaPool.release(resourceID);
    }
    const container = event.target.closest(".rm-embed-container.rm-loading");
    if (container) {
        container.classList.remove("rm-loading");
        container.classList.add("rm-loaded");
        if (controlSpacer(event.target, container)) {
            const iframe = event.target.querySelector("iframe");
            iframe.style.height = `${container.getBoundingClientRect().height}px`;
        }
    }
}

function onIntersect(entry) {
    function onFreeSlot(resourceID) {
        let tweet = entry.target;

        if (tweet.matches(".rm-embed-twitter")) {
            tweet = tweet.querySelector("blockquote.twitter-custom-tweet");
        }

        if (tweet.dataset.loading) {
            mediaPool.release(resourceID);
            return;
        }

        if (!tweet.dataset.twitterTweetId) {
            tweet.dataset.twitterTweetId = tweet.dataset.lazyTweetId;
        }

        tweet.dataset.loading = true;

        activeTwitterRequests.push(resourceID);
        tweet.classList.add("twitter-tweet");
        window.twttr.widgets.load(tweet.parentElement);
    }

    mediaPool.request(1200).then(onFreeSlot);
}

function onDependencyLoad() {
    twttr.ready(function onTwitterReady() {
        twttr.events.bind("rendered", releaseTweetResource);
    });
}

function invalidate(element) {
    element.classList.remove("twitter-tweet");
    element.dataset.lazyTweetId = element.dataset.twitterTweetId;
    delete element.dataset.twitterTweetId;
}

function beforeDependencyLoad() {
    document.querySelectorAll("blockquote.twitter-custom-tweet").forEach(invalidate);
}

export default function loadLazyTwitter(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        onDependencyLoad,
        beforeDependencyLoad,
        selector: ".rm-embed-twitter,blockquote.twitter-custom-tweet",
        dependencySrc: "https://platform.twitter.com/widgets.js",
        initialThreshold: 400,
    });
}
