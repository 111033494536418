import noop from "./lib/core/functional/noop";

const error = new Error("User is not logged in");
const waiters = [];
const listeners = [];

function upgrade(context) {
    if (context.settings.isUserLoggedIn) {
        import("./upgradeToLoggedIn").then((module) => module.default(context, waiters, listeners));
    }
}

export function doUpgradeToLoggedIn(context) {
    if (context.settings.isUserLoggedIn) {
        return context;
    }
    context.settings.isUserLoggedIn = true;
    upgrade(context);
    return context;
}

export default function upgradeToLoggedInExperienceLoader(context) {
    context.refreshUserInfo = noop;
    context.getUserInfo = () =>
        new Promise((resolve, reject) => {
            if (context.settings.isUserLoggedIn) {
                waiters.push([resolve, reject]);
            } else {
                reject(error);
            }
        });

    context.listenForUserInfo = (cb) => {
        listeners.push(cb);
        if (!context.settings.isUserLoggedIn) {
            cb(error, null);
        }
    };

    upgrade(context);

    return context;
}
