import { isPostPublished } from "../utils/postStatus";

function customNameReducer(acc, [key, value]) {
    acc[`_${key}`] = value;
    return acc;
}

function customizeNames(obj) {
    if (typeof obj !== "object") {
        return {};
    }

    return Object.entries(obj).reduce(customNameReducer, {});
}

const IEEE_SPECTRUM_SITE_ID = 20265424;

const SPECIAL_LOGIC = {
    //IEEE spectrum
    [IEEE_SPECTRUM_SITE_ID]() {
        window.PARSELY.updateDefaults({
            data: {
                level: window.REBELMOUSE_BOOTSTRAP_DATA.isUserLoggedIn
                    ? localStorage.getItem("userMembershipStatus")
                        ? localStorage.getItem("userMembershipStatus")
                        : "anonymous"
                    : "anonymous",
            },
        });
    },
};

function shouldSendParselyStats() {
    const isParselyReady =
        window.PARSELY &&
        window.PARSELY.beacon &&
        typeof window.PARSELY.beacon.trackPageView === "function";

    const isPostPage = Object.keys(window.REBELMOUSE_BOOTSTRAP_DATA.post).length > 0;
    const isPublishedPostPage =
        isPostPage && isPostPublished(window.REBELMOUSE_BOOTSTRAP_DATA.post);

    return isParselyReady && (!isPostPage || isPublishedPostPage);
}

function sendStats(data, pageInfo) {
    if (!shouldSendParselyStats()) {
        return;
    }

    if (window.REBELMOUSE_BOOTSTRAP_DATA.site.id in SPECIAL_LOGIC) {
        SPECIAL_LOGIC[window.REBELMOUSE_BOOTSTRAP_DATA.site.id]();
    }

    const url = (pageInfo && pageInfo.url) || document.URL;
    const urlref = (pageInfo && pageInfo.previousUrl) || document.referrer;

    window.PARSELY.beacon.trackPageView({
        ...data,
        url,
        urlref,
    });
}

function trackPageView(pageInfo) {
    sendStats({ js: 1 }, pageInfo);
}

function trackCustomEvent(action, data) {
    sendStats({
        action,
        data: customizeNames(data),
    });
}

function trackPageLoad(data) {
    trackCustomEvent("_pageLoad", data);
}

function trackPostMouseOver(data) {
    trackCustomEvent("_post_mouse_over", data);
}

function trackTimeOnPage({ timeMarker }) {
    trackCustomEvent("_page-view-duration", timeMarker);
}

function trackPostCardClick(data) {
    trackCustomEvent("_post_card_click", data);
}

export default {
    trackPageView,
    trackPageLoad,
    trackPostMouseOver,
    trackTimeOnPage,
    trackPostCardClick,
};
