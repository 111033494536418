export default { trackPageView, trackEvent, trackPostCardClick, trackPostShare };

export function waitForGtag(callback) {
  if (typeof window.gtag === "function") {
    callback(window.gtag);
  } else {
    setTimeout(() => {
      waitForGtag(callback);
    }, 100);
  }
}

function trackGtagCall(customDimensions, callback) {
  waitForGtag((gtag) => {
    const dimensionsData = customDimensions
      ? buildCustomDimensions(customDimensions)
      : {};

    callback(gtag, "event", "send", dimensionsData);
  });
}

function trackPageView({ url, customDimensions, headline, id }) {
  const titleElement = document.querySelector(`[elid="${id}"] .headline`);

  const params = {
    page_title: titleElement ? titleElement.innerText : headline,
    page_location: url,
  };

  trackGtagCall(customDimensions, (gtag, event, send, additionalInfo) =>
    gtag(event, "page_view", { ...params, ...additionalInfo })
  );
}

function trackEvent({ eventType, action, customDimensions }) {
  trackGtagCall(customDimensions, (gtag, event, send, params) =>
    gtag(event, action, { event_category: eventType, ...params })
  );
}

function trackPostCardClick(context) {
    trackEvent({
        ...context,
        eventType: 'post_interaction',
        action: 'post_card_click',
    });
}

function trackPostShare({ customDimensions, provider }) {
    trackGtagCall(
        customDimensions,
        (gtag, event) => gtag(
            event,
            'share',
            {
                method: provider,
                content_type: 'post',
            }
        )
    )
    trackEvent({
        customDimensions,
        eventType: 'share_click-post',
        action: 'click-post',
    });
}

function buildCustomDimensions(customDimensionsConfig) {
  return Object.entries(customDimensionsConfig).reduce((acc, [iden, value]) => {
    return { ...acc, ...{ [`dimension${iden}`]: value.toString() } };
  }, {});
}
