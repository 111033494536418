import { PAGE_LOAD_EVENT, POST_SHARED_EVENT, URL_CHANGE_EVENT, URL_SCROLLED_EVENT } from "./consts";

import assemblerSlideshowLoader from "./views/assemblerSlideshowLoader";
import bodyImageSharesLoader from "./views/shares/bodyImageSharesLoader";
import changeUrlOnScrollLoader from "./views/changeUrlOnScrollLoader";
import floatingElementsLoader from "./views/floatingElementsLoader";
import initHamburgerMenu from "./views/hamburgerMenu";
import initProductsLoader from "./views/products/loader";
import keepReadingLoader from "./views/keepReading/loader";
import liveBlogLoader from "./views/liveBlog/loader";
import livePostParticleLoader from "./views/liveBlog/postParticleLoader";
import userBehaviorLoader from "./views/userBehavior/loader";
import loadLazyMedia from "./views/mediaLazyLoad";
import loginSignupLoader from "./views/loginSignup/loader";
import nativeTalksLoader from "./views/nativeTalks/loader";
import pageFetchLoader from "./views/pageFetch/loader";
import pipe from "./lib/core/functional/pipe";
import noop from "./lib/core/functional/noop";
import postShares from "./views/shares/postShares";
import scrollParticleIntoView from "./views/scrollParticleIntoView";
import scrollTriggerLoader from "./views/scrollTriggerLoader";
import doubleStickyLoader from "./views/doubleStickyLoader";
import searchForm from "./views/searchForm";
import statsProvider from "./stats";
import stickyHeader from "./views/stickyHeader";
import subscriptionFormLoader from "./views/subscriptionFormLoader";
import tabsLoader from "./views/tabs/loader";
import videoPlayerLoader from "./views/videoPlayerLoader";
import uplynkLoader from "./views/uplynk/loader";
import assemblerUpDownVotingLoader from "./views/assemblerUpDownVoting/loader";
import gdprCookieAcceptHandler from "./views/gdprCookieAcceptHandler.js";
import followButtonLoader from "./views/followButton/loader";
import likeButtonLoader from "./views/likeButton/loader";
import foldersButtonLoader from "./views/folders/foldersButton/loader";
import foldersPostManagerLoader from "./views/folders/foldersPostManager/loader";
import loadLegacyLazyScripts from "./views/mediaLazyLoad/lazyScript";
import onPostLcp from "./utils/onPostLcp.js";
import createIdleQueue from "./utils/createIdleQueue";
import upgradeToLoggedInExperience from "./upgradeToLoggedInLoader";
import moduleLoader from "./utils/moduleLoader";
import initStdLib from "./stdlib";
import initAdLoader from "./adLoader";
import loadLdDebugger from "./ldDebugger/loader";

const HIGH_PRIORITY_TASK_TIMEOUT = 200;
const LOW_PRIORITY_TASK_TIMEOUT = 700;

function triggerLoaded(context) {
    let eventData = { url: location.href };
    if (context.settings.post) {
        eventData["id"] = context.settings.post.id;
    }
    context.eventBus.publish(PAGE_LOAD_EVENT, eventData);

    return context;
}

function urlEventListener(context) {
    const eventMap = {
        [URL_CHANGE_EVENT]: [
            "rebelmouse.urlChange",
            (data) => {
                window.history.replaceState({}, data.headline || "", data.url);
            },
        ],
        [URL_SCROLLED_EVENT]: ["rebelmouse.urlScrolled"],
        [POST_SHARED_EVENT]: ["rebelmouse.postShared"],
    };

    Object.keys(eventMap).forEach((busName) => {
        const eventData = eventMap[busName];
        context.eventBus.subscribe(busName, (detail) => {
            const [eventName, eventAction] = eventData;
            const customEvent = new CustomEvent(eventName, { detail });
            document.dispatchEvent(customEvent);
            if (eventAction) {
                eventAction(detail);
            }
        });
    });

    return context;
}

function addTaskToQueue(queue, timeout) {
    return function onTask(task) {
        return queue.pushTask(task, timeout);
    };
}

function loadUnparsedQueue(tasksQueue, queueName, queueSelector, timeout) {
    return function processQueue() {
        const queueElement = document.querySelector(queueSelector);

        if (queueElement && queueElement.textContent) {
            const parsedJavascript = document.createElement("script");
            parsedJavascript.textContent = queueElement.textContent;
            document.head.appendChild(parsedJavascript);
            queueElement.remove();
        }

        if (queueName && queueName.length) {
            queueName.forEach(addTaskToQueue(tasksQueue, timeout));
            queueName.length = 0;
        }
    };
}

function prepareLowPriorityQueues(tasksQueue, queue) {
    return function onPageIdle() {
        queue.forEach(addTaskToQueue(tasksQueue, LOW_PRIORITY_TASK_TIMEOUT));
    };
}

function initIdleQueue(tasksQ, lowerPriorityTasksQ, lowestPriorityTaskQ, activePriorityQueue) {
    const tasksQueue = createIdleQueue(noop);
    return function startIdleQueue(context) {
        tasksQ.forEach(addTaskToQueue(tasksQueue, HIGH_PRIORITY_TASK_TIMEOUT));

        const activeQueueLoader = loadUnparsedQueue(
            tasksQueue,
            activePriorityQueue,
            "script#user-javascript-active",
            HIGH_PRIORITY_TASK_TIMEOUT
        );

        const lowestQueueLoader = loadUnparsedQueue(
            tasksQueue,
            lowestPriorityTaskQ,
            "script#user-javascript-lowest",
            LOW_PRIORITY_TASK_TIMEOUT
        );
        lowerPriorityTasksQ.push(lowestQueueLoader);
        lowestPriorityTaskQ.push(loadLegacyLazyScripts);

        const handlePriorityQueues = pipe(
            activeQueueLoader,
            prepareLowPriorityQueues(tasksQueue, lowerPriorityTasksQ)
        );

        onPostLcp(handlePriorityQueues);
        return context;
    };
}

window.REBELMOUSE_INIT_IDLE_QUEUE = initIdleQueue;

const moduleList = [
    scrollParticleIntoView,
    initHamburgerMenu,
    loadLazyMedia,
    stickyHeader,
    searchForm,
    postShares,
    keepReadingLoader,
    pageFetchLoader,
    bodyImageSharesLoader,
    doubleStickyLoader,
    scrollTriggerLoader,
    changeUrlOnScrollLoader,
    assemblerSlideshowLoader,
    floatingElementsLoader,
    liveBlogLoader,
    subscriptionFormLoader,
    videoPlayerLoader,
    uplynkLoader,
    nativeTalksLoader,
    livePostParticleLoader,
    userBehaviorLoader,
    loginSignupLoader,
    tabsLoader,
    initProductsLoader,
    assemblerUpDownVotingLoader,
    gdprCookieAcceptHandler,
    followButtonLoader,
    likeButtonLoader,
    foldersButtonLoader,
    foldersPostManagerLoader,
];

const bootQueue = initIdleQueue(
    window.REBELMOUSE_TASKS_QUEUE,
    window.REBELMOUSE_LOW_TASKS_QUEUE,
    window.REBELMOUSE_LOWEST_TASKS_QUEUE,
    window.REBELMOUSE_ACTIVE_TASKS_QUEUE
);

function reactiveQueueLoad(context) {
    function onPageRendered() {
        bootQueue(context);
    }

    if (document.prerendering) {
        document.addEventListener("prerenderingchange", onPageRendered, { once: true });
    } else {
        onPageRendered();
    }

    return context;
}

const startApp = pipe(
    statsProvider,
    triggerLoaded,
    urlEventListener,
    initStdLib,
    initAdLoader,
    reactiveQueueLoad,
    moduleLoader(moduleList),
    upgradeToLoggedInExperience,
    loadLdDebugger
);

export default startApp;
