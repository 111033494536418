import createElementChangeListener from "../elementChangeListener";

export default function gdprCookieAcceptHandler(context) {
    if (window.localStorage.getItem("gdpr-cookie")) return context;

    createElementChangeListener(".gdpr-cookie", (el) => {
        el.classList.remove("hidden");
    });

    createElementChangeListener(".gdpr-cookie-accept", (el) => {
        el.addEventListener("click", () => {
            window.localStorage.setItem("gdpr-cookie", "accepted");
            document.querySelectorAll(".gdpr-cookie").forEach(node => {
                node.classList.add("hidden");
            })
        })
    });

    return context;
}
