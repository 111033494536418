import noop from "../lib/core/functional/noop";
import thunk from "../lib/core/functional/thunk";
import curry from "../lib/core/functional/curry";
import pipe from "../lib/core/functional/pipe";
import call from "../lib/core/functional/call";

import intersectionObserverProvider from "../context/intersectionObserverProvider";

import onPostLcp from "../utils/onPostLcp";
import {
    getConnectionType,
    listenToConnectionChanges,
    stopListening,
} from "../utils/connectionHandler";
import loadExternalScript from "../utils/loadExternalScript";
import { requestIdleCallback, cancelIdleCallback } from "../utils/idleCallback.js";

let scrollTimeoutId;
let idleCallbackId;
const idleCallbacks = new Set();
const callbackRunner = call();

const loadDeps = thunk(function loadDeps(props, state) {
    const { dependencies } = props;
    const allDeps = dependencies.length;
    let loaded = 0;

    function onDepLoaded() {
        loaded++;
        if (loaded === allDeps) {
            startAdLifeCycle(props, state);
        }
    }

    function onEachDep(dep) {
        loadExternalScript(dep, onDepLoaded);
    }

    if (allDeps > 0) {
        dependencies.forEach(onEachDep);
    } else {
        startAdLifeCycle(props, state);
    }
});

const idleHandler = curry(function idleHandler(removeFromSet, addToSet, element) {
    removeFromSet.delete(element);
    addToSet.add(element);
});

const nonIdleHandler = curry(function nonIdleHandler(handler, element) {
    handler(element);
});

const initiateStaticElement = curry(function initiateStaticElement(displayer, element) {
    markAsStaticElement(element);
    displayer(element);
});

export default function init(context) {
    window.RM_AD_LOADER.forEach(processAdRequest);
    upgradeObject();
    return context;
}

function upgradeObject() {
    window.RM_AD_LOADER = { push: processAdRequest };
}

function processAdRequest(props) {
    const { onInit } = props;
    const state = onInit.call(window);
    onPostLcp(loadDeps(props, state));
}

function startAdLifeCycle(props, state) {
    const {
        options,
        staticSelectors = [],
        dynamicSelectors = [],
        disableIdleLoader,
        disableVisibilityWatcher,
    } = props;
    const {
        displayOnElement,
        refreshOnElement,
        destroyOnElement,
        onDependanciesLoaded,
    } = getCallbacks(props);

    let connectionChangeCallback;
    let refreshRate;
    let intervalId;
    let timeoutId;
    let intersectHandler;
    let nonIntersectHandler;
    let onIterationEnd;
    let userOptions;
    let lastRefresh = Date.now();

    let previousObsevers = [];
    let staticElements = [];
    let setOptions = saveOptions;

    const elementsToDestroy = new Set();
    const elementsToRefresh = new Set();
    const elementsToDisplay = new Set();
    const observerSelector = dynamicSelectors.join(",");

    const displayIdleHandler = idleHandler(elementsToDestroy, elementsToDisplay);
    const displayNonIdleHandler = nonIdleHandler(displayElement);

    const destroyIdleHandler = idleHandler(elementsToDisplay, elementsToDestroy);
    const destroyNonIdleHandler = nonIdleHandler(destroyElement);

    if (disableIdleLoader) {
        intersectHandler = displayNonIdleHandler;
        nonIntersectHandler = destroyNonIdleHandler;
        onIterationEnd = noop;
    } else {
        intersectHandler = displayIdleHandler;
        nonIntersectHandler = destroyIdleHandler;
        onIterationEnd = waitForIdle;
    }

    if (staticSelectors.length) {
        setOptions = pipe(saveOptions, setUpAdRefresh);
        staticElements = Array.from(document.querySelectorAll(staticSelectors.join(",")));
    }

    if (observerSelector) {
        setOptions = pipe(saveOptions, setUpAdRefresh, setUpIntersectionObserver);
    }

    onDependanciesLoaded(state, setOptions);
    setOptions(options || {});
    staticElements.forEach(initiateStaticElement(displayElement));

    function saveOptions(options) {
        userOptions = options;
    }

    function displayElement(element) {
        elementsToRefresh.add(element);
        element.dataset.rmLastViewed = Date.now();
        element.dataset.initiated = true;
        displayOnElement(element, state, setOptions);
    }

    function refreshElement(element) {
        if (Date.now() - parseInt(element.dataset.rmLastViewed, 10) < refreshRate) {
            return;
        }

        element.dataset.rmLastViewed = Date.now();
        element.dataset.refreshCount = element.dataset.refreshCount
            ? parseInt(element.dataset.refreshCount, 10) + 1
            : 1;
        refreshOnElement(element, state, setOptions);
    }

    function destroyElement(element) {
        destroyOnElement(element, state, setOptions);
    }

    function setUpIntersectionObserver() {
        previousObsevers.forEach(disconnect);
        previousObsevers = intersectionObserverProvider.addTask({
            onNonIntersect,
            onIntersect,
            entriesHandler,
            selector: observerSelector,
            threshold: getThreshold(),
        });
    }

    function setUpAdRefresh() {
        const { refreshTimeout } = userOptions;
        if (!refreshTimeout || refreshTimeout === refreshRate) {
            return;
        }

        refreshRate = refreshTimeout;
        startInterval(refreshTimeout);
    }

    function startInterval() {
        clearInterval(intervalId);
        intervalId = setInterval(triggerRefresh, refreshRate);
    }

    function triggerRefresh() {
        waitForIdle(iterateRefreshElements);
    }

    function iterateRefreshElements() {
        lastRefresh = Date.now();
        elementsToRefresh.forEach(refreshElement);
    }

    function getThreshold() {
        const { threshold } = userOptions;

        if (connectionChangeCallback) {
            stopListening(connectionChangeCallback);
        }

        if (!isNaN(threshold)) {
            connectionChangeCallback = null;
            return threshold;
        }
        let ogConnectionType = getConnectionType();

        connectionChangeCallback = function onConnectionChanged(newType) {
            if (ogConnectionType !== newType) {
                setOptions(userOptions);
            }
        };
        listenToConnectionChanges(connectionChangeCallback);
        const currentThreshold = threshold[ogConnectionType];
        return currentThreshold;
    }

    function entriesHandler(entries) {
        entries.forEach(onEntry);
        onIterationEnd(executeIdleCallbacks);
    }

    function onEntry(entry) {
        if (entry.isIntersecting) {
            onIntersect(entry, intersectHandler);
        } else {
            onNonIntersect(entry, nonIntersectHandler);
        }
    }

    function onIntersect(entry, handler) {
        const element = entry.target;
        handler(element);
    }

    function onNonIntersect(entry, handler) {
        const element = entry.target;
        if (!element.dataset.initiated) {
            return;
        }
        elementsToRefresh.delete(element);
        handler(element);
    }

    function executeIdleCallbacks() {
        iterateAndClearSet(elementsToDisplay, displayElement);
        iterateAndClearSet(elementsToDestroy, destroyElement);
    }

    if (!disableVisibilityWatcher) {
        function onVisibilityChange() {
            if (!refreshRate) {
                return;
            }

            if (document.visibilityState === "visible") {
                startInterval();
                const timeSinceLastRefresh = Date.now() - lastRefresh;
                if (timeSinceLastRefresh >= refreshRate) {
                    triggerRefresh();
                } else {
                    const difference = refreshRate - timeSinceLastRefresh;
                    if (difference <= refreshRate / 4) {
                        timeoutId = setTimeout(triggerRefresh, difference);
                    }
                }
            } else {
                clearTimeout(timeoutId);
                clearInterval(intervalId);
            }
        }

        document.addEventListener("visibilitychange", onVisibilityChange);
    }
}

function waitForIdle(callback) {
    idleCallbacks.add(callback);
    clearTimeout(scrollTimeoutId);
    cancelIdleCallback(idleCallbackId);
    scrollTimeoutId = setTimeout(waitForIdleMainThread, 64);
}

function waitForIdleMainThread() {
    idleCallbackId = requestIdleCallback(runIdleCallbacks, { timeout: 100 });
}

function runIdleCallbacks() {
    idleCallbacks.forEach(callbackRunner);
    idleCallbacks.clear();
}

function getCallbacks(props) {
    const {
        displayOnElement = noop,
        refreshOnElement = noop,
        destroyOnElement = noop,
        onDependanciesLoaded = noop,
    } = props;

    return {
        displayOnElement: displayOnElement.bind(window),
        refreshOnElement: refreshOnElement.bind(window),
        destroyOnElement: destroyOnElement.bind(window),
        onDependanciesLoaded: onDependanciesLoaded.bind(window),
    };
}

function disconnect(observer) {
    observer.disconnect();
}

function markAsStaticElement(element) {
    element.dataset.isStatic = 1;
}

function iterateAndClearSet(set, callback) {
    set.forEach(callback);
    set.clear();
}
