/**
 * @param {string} message
 * @param {'success' | 'error' | 'notice'} type
 * @param {number} [timeout]
 */
export default function raiseNotification(message, type, timeout) {
    let notificationList = document.querySelector("#notification");
    let notificationListItem = document.createElement("li");

    if (!notificationList) {
        notificationList = document.createElement("ul");
        notificationList.setAttribute("id", "notification");
        notificationList.classList.add("notification");
        document.body.prepend(notificationList);
    }

    type = type || "";
    timeout = timeout || 5000;

    notificationListItem.innerText = message;
    notificationListItem.classList.add(type);
    notificationList.append(notificationListItem);
    notificationList.style.display = "block";

    setTimeout(() => {
        notificationList.removeChild(notificationListItem);
    }, timeout);
}
