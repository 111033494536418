export function controlSpacer(embed, container) {
    if (!container || !embed) {
        return false;
    }

    const { bottom: embedBottom, height: embedHeight } = embed.getBoundingClientRect();
    const { bottom: containerBottom, height: containerHeight } = container.getBoundingClientRect();

    if (embedBottom < 0 && containerBottom < 0) {
        return true;
    }

    if (embedHeight > containerHeight + 10) {
        container.classList.add("rm-off");
        return false;
    } else {
        return true;
    }
}
