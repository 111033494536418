export default function createTimer(callback, delayList, maxTotalDuration) {
    const timerCreationTime = +new Date();
    let timerId;
    let startTime = 0;
    let remaining = delayList[0];

    const stop = () => {
        clearTimeout(timerId);
    };

    const pause = () => {
        stop();
        remaining -= new Date() - startTime;
    };

    const start = step => {
        startTime = +new Date();
        timerId = setTimeout(() => {
            step = step || 0;
            if (startTime - timerCreationTime > maxTotalDuration) {
                return;
            }

            callback(remaining);
            step += 1;
            remaining = delayList[step] || delayList[delayList.length - 1];
            start(step);
        }, remaining);
    };

    return {
        start,
        pause,
        stop
    };
}
