import fromNullable from "../lib/core/functional/fromNullable";
import thunk from "../lib/core/functional/thunk";

import { retrieveJsonRequest } from "./utils";

let bootstrap;

const fetchFullBootstrap = thunk((bootstrapUrl) => retrieveJsonRequest(bootstrapUrl));

function getStoredBootstrap() {
    return fromNullable(bootstrap);
}

function cacheBootstrap(loadedBootstrap) {
    bootstrap = loadedBootstrap;
    return bootstrap;
}

export default function fullBootstrap(context) {
    return getStoredBootstrap()
        .catch(fetchFullBootstrap(context.settings.fullBootstrapUrl))
        .then(cacheBootstrap);
}
