const STATUS_ADMIN = 0;
const STATUS_COMMUNITY = 1;
const STATUS_FEATURED = 2;

const PUBLISHED_POST_STATUSES = [STATUS_ADMIN, STATUS_COMMUNITY, STATUS_FEATURED];

export function isPostPublished(post) {
    const { status } = post;
    return PUBLISHED_POST_STATUSES.includes(parseInt(status, 10));
}
