export default function scrollParticleIntoView(context) {
    window.addEventListener("load", () => {
        const selector = `[data-href="https://${location.host}${location.pathname}"]`;
        const elementToScroll = document.querySelector(selector);
        if (elementToScroll) {
            elementToScroll.scrollIntoView({block: 'nearest'});
        }
    });
    return context;
}
