import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";
import { controlSpacer } from "./utils";

const instagramClass = "instagram-media";
const lazyInstagramClass = "lazy-instagram-media";

const activeIGRequests = [];
let oldHandler;

function releaseIGResource(frame) {
    const igEmbed = document.getElementById(frame.frameId);
    igEmbed.addEventListener("load", function () {
        const container = igEmbed.closest(".rm-embed-container.rm-loading");
        if (container) {
            container.classList.remove("rm-loading");
            container.classList.add("rm-loaded");
            setTimeout(() => {
                controlSpacer(igEmbed, container);
            }, 500);
        }
    });

    if (oldHandler) {
        oldHandler(frame);
    }
    const resourceID = activeIGRequests.shift();
    if (resourceID) {
        mediaPool.release(resourceID);
    }
}

function onIntersect(entry) {
    function onFreeSlot(resourceID) {
        entry.target.classList.add(instagramClass);
        entry.target.classList.remove(lazyInstagramClass);
        window.instgrm.Embeds.process();
        activeIGRequests.push(resourceID);
    }
    mediaPool.request(1700).then(onFreeSlot);
}

function invalidate(element) {
    element.classList.remove(instagramClass);
    element.classList.add(lazyInstagramClass);
}

function beforeDependencyLoad() {
    document.querySelectorAll("blockquote.instagram-media").forEach(invalidate);
}

export default function loadLazyInstagram(intersectionObserverProvider) {
    if (window.__igEmbedLoaded) {
        oldHandler = window.__igEmbedLoaded;
    }
    window.__igEmbedLoaded = releaseIGResource;
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        beforeDependencyLoad,
        onAppear: invalidate,
        initialThreshold: 300,
        selector: "blockquote.instagram-media,blockquote.lazy-instagram-media",
        dependencySrc: "https://www.instagram.com/embed.js",
    });
}
