import { openPopup } from "./common";
import { POST_SHARED_EVENT } from "../../consts";
import createElementChangeListener from "../../elementChangeListener";
import raiseNotification from "../../utils/raiseNotification";

const selector = ".share-panel a";

function popupHandler(el, eventBus) {
    el.addEventListener("click", (event) => {
        const { currentTarget } = event;
        const { trackShare } = currentTarget.dataset;
        const href = currentTarget.href;
        if ( trackShare != "Email" && trackShare != "Sms" ) {
            event.preventDefault();
            event.stopPropagation();
        }
        openPopup(
            href,
            (trackShare || "").toLowerCase()
        );
        eventBus.publish(POST_SHARED_EVENT, {
            url: href,
            provider: (trackShare || "").toLowerCase()
        });
        return false;
    }, true);
}

function copyTextHandler(el) {
    el.href = "javascript:void(0)";
    el.addEventListener("click", (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(
            event.currentTarget.dataset.clipboardText
        );
        raiseNotification("copied to clipboard", "success");
    });
}

function plusButtonHandler(el) {
    const buttonsList = el.parentElement.children;
    let found = false;
    const hideButtonList = Array.from(buttonsList).reduce((acc, button) => {
        found && acc.push(button);
        found = found || button === el;
        return acc;
    }, []);

    hideButtonList.forEach((button) => (button.style.display = "none"));

    el.addEventListener("click", (event) => {
        event.currentTarget.remove();
        hideButtonList.forEach(
            (button) => (button.style.display = "inline-block")
        );
    });
}

function initShareButtonFactory(el, eventBus) {
    if (el.dataset.clipboardText) {
        return copyTextHandler(el);
    } else if (el.classList.contains("share-plus")) {
        return plusButtonHandler(el);
    } else {
        return popupHandler(el, eventBus);
    }
}

export default function postShares(context) {
    createElementChangeListener(selector, (el) => {
        initShareButtonFactory(el, context.eventBus)
    });
    return context;
}
