export default function createElementChangeListener(selector, callback, oneTime, target) {
    const initializedNodes = new Set([]);
    let reported = false;

    const root = target || document;
    const observeRoot = target || document.body;

    root.querySelectorAll(selector).forEach((node) => {
        if (oneTime && reported) {
            return;
        }
        reported = true;
        callback(node);
        initializedNodes.add(node);
    });

    const mutationObserver = new MutationObserver(() => {
        const nodes = root.querySelectorAll(selector);
        nodes.forEach((node) => {
            if (oneTime && reported) {
                return;
            }

            if (!initializedNodes.has(node)) {
                reported = true;
                callback(node);
                initializedNodes.add(node);
                if (oneTime) {
                    mutationObserver.disconnect();
                }
            }
        });
    });

    if (!oneTime || !reported) {
        mutationObserver.observe(observeRoot, {
            childList: true,
            subtree: true,
        });
    }
    return mutationObserver;
}
