import createElementChangeListener from "../../elementChangeListener";

function onUplynkElementChange(node) {
    node.addEventListener('click', function handler() {
        node.removeEventListener("click", handler, false);
        node.removeChild(node.firstElementChild);
        if (window.REBELMOUSE_BOOTSTRAP_DATA.engine.defaultVideoPlayer === 'theo') {
            import("../theoplayer/module").then((module) => module.default(node));
        }
    } , false);
}

export default function uplynkLoader(context) {
    createElementChangeListener(".uplynk-container", onUplynkElementChange, false);
}
