import loadModuleOnScroll from "../../utils/loadModuleOnScroll";
import onPostLcp from "../../utils/onPostLcp";

export default function pageFetchLoader(context) {
    function onModule(module) {
        module.default(context);
    }

    loadModuleOnScroll(() => {
        import("./module").then(onModule);
    });

    onPostLcp(() => {
        import("./postLcp").then(onModule);
    });

    return context;
}
