import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";
import { controlSpacer } from "./utils";

const TIKTOK_CLASS = "tiktok-embed";
const LAZY_TIKTOK_CLASS = "tiktok_lazy_shortcode";

function waitForMilis(miliseconds) {
    function resolver(resolve) {
        setTimeout(resolve, miliseconds);
    }

    return new Promise(resolver);
}

async function getTiktokLib(attemps) {
    console.log(attemps);
    if (attemps < 0) {
        return false;
    }

    if (window.tiktokEmbed && "lib" in window.tiktokEmbed) {
        return window.tiktokEmbed.lib;
    }

    await waitForMilis(100);

    return getTiktokLib(--attemps);
}

function onIntersect(entry) {
    const { target } = entry;

    async function onFreeSlot(resourceID) {
        function releaseTiktokResource() {
            mediaPool.release(resourceID);
        }

        function handleGracefulLoad() {
            const container = target.closest(".rm-embed-container.rm-loading");
            if (!container) {
                return;
            }
            container.classList.remove("rm-loading");
            container.classList.add("rm-loaded");
            setTimeout(() => {
                controlSpacer(target, container);
            }, 1500);
        }

        const tiktokLib = await getTiktokLib(50);
        target.classList.add(TIKTOK_CLASS);
        target.classList.remove(LAZY_TIKTOK_CLASS);

        if (tiktokLib) {
            tiktokLib.render([target]).then(releaseTiktokResource).then(handleGracefulLoad);
        } else {
            releaseTiktokResource();
        }
    }
    mediaPool.request(1700).then(onFreeSlot);
}

export default function loadLazyReddit(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        initialThreshold: 400,
        selector: ".tiktok_lazy_shortcode",
        dependencySrc: "https://www.tiktok.com/embed.js",
    });
}
