const popupDimension = {
    twitter: [450, 240],
    pinterest: [750, 546],
    default: [550, 425]
};

export function openPopup(link, provider) {
    if(link) {
        const [width, height] = popupDimension[provider] || popupDimension.default;
        const left = Math.max((window.innerWidth - width) / 2, 0);
        const top = Math.max((window.innerHeight - height) / 2, 0);
        const winSpec = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
        if (link.substr(0, 4) !== 'tel:' && link.substr(0, 7) !== 'mailto:' && link.substr(0, 4) !== 'sms:') {
            return window.open(link, "_blank", winSpec);
        }
    }
}
