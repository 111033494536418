import loadLazyFacebook from "./lazyFacebook";
import loadLazyIFrames from "./lazyIframe";
import loadLazyImages from "./lazyImage";
import loadLazyInstagram from "./lazyInstagram";
import loadLazyReddit from "./lazyReddit";
import loadLazyTwitter from "./lazyTwitter";
import loadLazyTikTok from "./lazyTikTok";
import loadLazyBrightcove from "./lazyBrightcove";
import loadModuleOnScroll from "../../utils/loadModuleOnScroll";
import viewportStyle from "./viewportStyle";
import loadLazyImgur from "./lazyImgur";
import loadLazyJWPlayer from "./jwplayer/loader";

export default function loadLazyMedia(context) {
    loadLazyJWPlayer(context);
    loadLazyImages(context.intersectionObserverProvider);
    loadLazyIFrames(context.intersectionObserverProvider);
    loadLazyInstagram(context.intersectionObserverProvider);
    loadLazyFacebook(context.intersectionObserverProvider);
    loadLazyReddit(context.intersectionObserverProvider);
    loadLazyTwitter(context.intersectionObserverProvider);
    loadLazyTikTok(context.intersectionObserverProvider);
    loadLazyBrightcove(context.intersectionObserverProvider);
    viewportStyle(context.intersectionObserverProvider);
    loadLazyImgur(context.intersectionObserverProvider);

    loadModuleOnScroll(() => {
        import("./lazyStyle").then((module) => {
            module.default();
        });
    });

    return context;
}
