import ga4Stats from "./ga4Stats";
import nativeStats from "./nativeStats";
import parselyStats from "./parselyStats";
import streamsScores from "./streamsScores";
import loadContextData from "../utils/loadContextData";
import updateMetaData from "./metadata";

const providers = [nativeStats, ga4Stats, parselyStats];

function getPostContext(post) {
  const id = post.getAttribute("elid");
  return { id, ...loadContextData(`.post-context-${id}`) };
}

export default {
  trackPageView(pageData) {
    const postContext = loadContextData(`.post-context-${pageData.id}`);
    const data = { ...pageData, ...postContext };
    updateMetaData(data);
    providers.forEach((provider) => {
      provider.trackPageView({...data});
    });
    if (postContext.post) {
      streamsScores.trackPageView(postContext.post);
    }
  },
  trackPageLoad(pageData) {
    const postContext = loadContextData(`.post-context-${pageData.id}`);
    nativeStats.trackPageView({ ...pageData, ...postContext });
    parselyStats.trackPageLoad({ ...pageData, ...postContext });
    if (postContext.post) {
      streamsScores.trackPageView(postContext.post);
    }
  },
  trackTimeOnPage(timeMarker) {
    nativeStats.trackTimeOnPage({ timeMarker });
    parselyStats.trackTimeOnPage({ timeMarker });
  },
  trackUser(pageData) {
    nativeStats.trackUser({ ...pageData });
  },
  trackPostCardClick(post) {
    providers.forEach(provider => {
      provider.trackPostCardClick(getPostContext(post));
    });
    const context = getPostContext(post);
    if (context.post) {
      streamsScores.trackPostCardClick(context.post);
    }
  },
  trackEvent(pageData) {
    const postContext = loadContextData(`.post-context-${pageData.id}`);
    ga4Stats.trackEvent({ ...pageData, ...postContext });
  },
  trackSocialShare(data) {
    const postContext = loadContextData(`.post-context-${data.id}`);
    ga4Stats.trackPostShare({ ...postContext, ...data });
  },
  batchPostCardView(data) {  // TODO: looks like it works for split testing only which does not make a lot of sense
    nativeStats.batchPostCardView(data);
  },
  trackPostCardView(post){
    nativeStats.trackPostCardView(getPostContext(post));
    const context = getPostContext(post);
    if (context.post) {
      streamsScores.trackPostCardView(context.post);
    }
  },
};
