const ENTER_KEY = 13;
const SEARCH_OPENED_CLASS = "show-search";

export default function searchForm(context) {
    const searchToggle = document.querySelector(".search-form__open");
    const searchForm = document.querySelector(".search-form");

    if (searchForm && searchToggle) {
        searchToggle.addEventListener("click", () => {
            searchForm.classList.toggle(SEARCH_OPENED_CLASS);
        });
    }

    document.addEventListener("keypress", event => {
        if (
            event.keyCode === ENTER_KEY &&
            document.body.classList.contains(SEARCH_OPENED_CLASS)
        ) {
            document.querySelector(".search-form > form").submit();
        }
    });

    return context;
}
