import createSmartIntersector from "../../utils/createSmartIntersector";
import * as mediaPool from "./_mediaPool";

function onIntersect(entry) {
    function onFreeSlot(resourceID) {
        function onLoad() {
            mediaPool.release(resourceID);
        }
        const { target } = entry;
        target.addEventListener("load", onLoad);
        const { runnerSrc } = target.dataset;
        if (runnerSrc) {
            target.src = runnerSrc;
            delete target.dataset.runnerSrc;
        }
    }

    mediaPool.request(1500).then(onFreeSlot);
}

export default function loadLazyIFrames(intersectionObserverProvider) {
    createSmartIntersector(intersectionObserverProvider, {
        onIntersect,
        selector: "iframe[data-runner-src]",
        initialThreshold: 500,
    });
}
