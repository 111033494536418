import createElementChangeListener from "../elementChangeListener";

export default function loadModuleOnElement(selector, moduleLoader, context, oneTime) {
    createElementChangeListener(
        selector,
        (element) => {
            moduleLoader().then((module) => {
                module.default(context, element);
            });
        },
        oneTime
    );
}
