import curry from "../lib/core/functional/curry";
import thunk from "../lib/core/functional/thunk";
import createElementChangeListener from "../elementChangeListener";

import { listenToConnectionChanges, getConnectionType } from "./connectionHandler";
import loadModuleOnScroll from "./loadModuleOnScroll";
import loadExternalScript from "./loadExternalScript";

const noop = () => {};
const defaultTreshholdCalculator = {
    "4g": function on4G() {
        return Math.max(window.innerHeight * 1.5, 700);
    },
    "3g": function on3G() {
        return Math.max(window.innerHeight * 2, 900);
    },
    "2g": function on2G() {
        return window.innerHeight * 3;
    },
    "slow-2g": function onSlow2G() {
        return window.innerHeight * 3;
    },
};

export default function createSmartIntersector(
    intersectionObserverProvider,
    {
        dependencySrc,
        selector,
        onIntersect,
        onAppear,
        beforeDependencyLoad = noop,
        onDependencyLoad = noop,
        oneTime = true,
        initialThreshold = 500,
        thresholdCalculator = {},
    }
) {
    const intersectorContext = createIntersectorContext({
        dependencySrc,
        onDependencyLoad,
        beforeDependencyLoad,
    });
    const onIntersectCallback = createIntersectorCallback(intersectorContext, onIntersect);

    const realTreshholdCalculator = {
        ...defaultTreshholdCalculator,
        ...thresholdCalculator,
    };
    const passiveObservers = observerCreator(
        intersectionObserverProvider,
        initialThreshold,
        selector,
        onIntersectCallback,
        onAppear,
        oneTime
    );
    const onScroll = createActiveObservers(
        passiveObservers,
        initialThreshold,
        realTreshholdCalculator,
        intersectionObserverProvider,
        selector,
        onIntersectCallback,
        onAppear,
        oneTime
    );

    loadModuleOnScroll(onScroll);

    if (dependencySrc) {
        loadModuleOnScroll(eagerDependencyLoader(intersectorContext, selector));
    }
}

function createActiveObservers(
    previousObservers,
    previousTreshhold,
    treshholdCalculator,
    intersectionObserverProvider,
    selector,
    onIntersectCallback,
    onAppear,
    oneTime
) {
    return function onChange() {
        const newThreshold = getActiveUserThreshold(treshholdCalculator);

        let changedObservers = previousObservers;
        if (newThreshold !== previousTreshhold) {
            previousObservers.observers.flatMap(flattenObserver).forEach(disconnectObserver);
            changedObservers = observerCreator(
                intersectionObserverProvider,
                newThreshold,
                selector,
                onIntersectCallback,
                onAppear,
                oneTime,
                previousObservers.task
            );
        }

        const nextChange = createActiveObservers(
            changedObservers,
            newThreshold,
            treshholdCalculator,
            intersectionObserverProvider,
            selector,
            onIntersectCallback,
            onAppear,
            oneTime
        );

        listenToConnectionChanges(nextChange, true);
    };
}

function flattenObserver(observer) {
    return observer;
}

function disconnectObserver(observer) {
    observer.disconnect();
}

function getActiveUserThreshold(treshholdCalculator) {
    return treshholdCalculator[getConnectionType()]();
}

function createIntersectorCallback(intersectorContext, onIntersect) {
    if (intersectorContext.needsDependency) {
        return createDependencyIntersectorLoader(intersectorContext, onIntersect);
    }
    return onIntersect;
}

const createDependencyIntersectorLoader = curry((intersectorContext, onIntersect, entry) => {
    if (intersectorContext.hasLoadedDependency) {
        onIntersect(entry);
        return;
    }

    function onDependencyLoad() {
        onIntersect(entry);
    }

    const dependencyLoader = createDependencyLoader(intersectorContext, onDependencyLoad);
    dependencyLoader();
});

function observerCreator(
    intersectionObserverProvider,
    threshold,
    selector,
    onIntersect,
    onAppear,
    oneTime,
    previousState = {}
) {
    const task = {
        ...previousState,
        selector,
        threshold,
        onAppear,
        oneTime,
        onIntersect,
    };

    return { task, observers: intersectionObserverProvider.addTask(task) };
}

function createIntersectorContext({ dependencySrc, onDependencyLoad, beforeDependencyLoad }) {
    let called = false;
    let beforeCalled = false;
    return {
        dependencySrc,
        onDependencyLoad() {
            if (!called) {
                called = true;
                onDependencyLoad();
            }
        },
        beforeDependencyLoad() {
            if (!beforeCalled) {
                beforeCalled = true;
                beforeDependencyLoad();
            }
        },
        needsDependency: dependencySrc != undefined,
        hasLoadedDependency: dependencySrc == undefined,
    };
}

const eagerDependencyLoader = thunk((intersectorContext, selector) => {
    if (!intersectorContext.needsDependency || intersectorContext.hasLoadedDependency) {
        return;
    }

    createElementChangeListener(
        selector,
        createDependencyLoader(intersectorContext, intersectorContext.onDependencyLoad),
        true
    );
});

const createDependencyLoader = thunk((intersectorContext, callback) => {
    intersectorContext.beforeDependencyLoad();
    loadExternalScript(intersectorContext.dependencySrc, function onLoad() {
        intersectorContext.hasLoadedDependency = true;
        intersectorContext.onDependencyLoad();
        callback();
    });
});
