import loadModuleOnScroll from "../utils/loadModuleOnScroll";
import createIdleScrollListener from "../utils/createIdleScrollListener";

const TOGGLE_CLASS_NAME = "with-fixed-header";

function positionOnScroll(triggerNode) {
    return createIdleScrollListener(() => {
        const { toggleClass } = triggerNode.dataset;
        if (triggerNode.getBoundingClientRect().top <= 0) {
            document.body.classList.add(toggleClass);
        } else {
            document.body.classList.remove(toggleClass);
        }
    });
}

export default function stickyElement(context) {
    loadModuleOnScroll(() => {
        const stickyHeader = document.querySelector(`[data-toggle-class=${TOGGLE_CLASS_NAME}]`);

        if (stickyHeader) {
            document.addEventListener("scroll", positionOnScroll(stickyHeader), { passive: true });
        }
    });

    return context;
}
