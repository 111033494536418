import getContext from "../context";

const PROTOCOL_VERSION = 1;
const MAX_STREAMS_PER_REQUEST = 16;

function trackPageView(data) {
    function _isPostPage() {
        const [resourceType] = getContext().settings.resourceId.split("_");
        return resourceType === "dp" || resourceType === "pp";
    }

    if (_isPostPage()) {
        _trackPostPageView(data);
    }
}

function _trackPostPageView({ id, streams }) {
    _asyncTrackPostEvent("post-page-view", {id, streams});
}

function trackPostCardClick({ id, streams }) {
    _asyncTrackPostEvent("post-teaser-click", {id, streams});
}

function trackPostCardView({ id, streams }) {
    _asyncTrackPostEvent("post-teaser-view", {id, streams});
}

function _asyncTrackPostEvent(eventType, { id, streams }) {
    _asyncTrackEvent(eventType, {"post": id}, streams);
}

function _asyncTrackEvent(eventType, entity, streams) {
    setTimeout(function () {
        _trackEvent(eventType, entity, streams);
    }, 0);
}

function _trackEvent(eventType, entity, streams) {
    function _calculateStreamScore(streamScoreEvents) {
        return Object.entries(streamScoreEvents).reduce((acc, [event, weight]) => {
            if (event === eventType) {
                acc += weight;
            }
            return acc;
        }, 0);
    }

    function _updateStreamScore(stream) {
        const [bucket, {period, events}] = Object.entries(stream)[0];
        return {[bucket]: {
            period: period,
            score: _calculateStreamScore(events),
        }};
    }

    const siteId = _getSiteId();
    const affectedStreams = streams.map(_updateStreamScore).filter(stream => stream[Object.keys(stream)[0]].score !== 0);
    while (affectedStreams.length > 0) {
        _send({
            entity: entity,
            site_id: siteId,
            streams: affectedStreams.splice(0, MAX_STREAMS_PER_REQUEST),
        });
    }
}

function _getSiteId() {
    return getContext().settings.runner.id;
}

function _send(payload) {
    const basePath = _getBasePath();
    fetch(`${basePath}?version=${PROTOCOL_VERSION}&format=json&data=${JSON.stringify(payload)}`);
}

function _getBasePath() {
    if (_shouldSendEventsToCurrentDomain()) {
        return `${window.location.origin}/core/stats/tracking/streams-scores`;
    }
    else {
        return 'https://streams-scores.rbl.ms/spacer.gif';
    }
}

function _shouldSendEventsToCurrentDomain() {
    return getContext().settings.engine.sendEventsToCurrentDomain;
}

export default {
    trackPageView,
    trackPostCardView,
    trackPostCardClick
};
