const possibleContainers = [
    ".rm-container-resized",
    ".rebellt-item",
    ".body-description",
    ".widget__img",
    "article",
];

export function findAppropiateParent(element) {
    function testSelector(foundElement, selector) {
        if (foundElement) {
            return foundElement;
        }

        const elementToTest = element.closest(selector);

        if (!elementToTest) {
            return null;
        }

        if (elementToTest.dataset && elementToTest.dataset.rmCalcWidth) {
            return elementToTest;
        }

        const elWidth = Math.ceil(elementToTest.offsetWidth / 100) * 100;

        if (elWidth > 0) {
            elementToTest.dataset.rmCalcWidth = elWidth;
            return elementToTest;
        } else {
            return null;
        }
    }

    return possibleContainers.reduce(testSelector, null);
}

export default function getMaxWidthAndHeight(width, height, containerWidth, containerHeight) {
    if (containerWidth > width) {
        return { maxWidth: width, maxHeight: height };
    }

    let maxWidth = containerWidth;
    let maxHeight = parseInt((maxWidth * height) / width, 10);
    if (containerHeight && maxHeight > containerHeight) {
        maxHeight = containerHeight;
        maxWidth = parseInt((maxHeight * width) / height, 10);
    }
    return { maxWidth, maxHeight };
}
