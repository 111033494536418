import createSmartIntersector from "../../utils/createSmartIntersector";
import { getConnectionType } from "../../utils/connectionHandler";
import translateParamsToObject from "../../utils/translateParamsToObject";
import {
    default as getMaxWidthAndHeight,
    findAppropiateParent,
} from "../../utils/getMaxWidthAndHeight";
import createElementChangeListener from "../../elementChangeListener";
import * as mediaPool from "./_mediaPool";

function pairToQueryParam([key, value]) {
    return `${key}=${value}`;
}

function objectToQS(obj) {
    return `?${Object.entries(obj).map(pairToQueryParam).join("&")}`;
}

const qualityToConnectionMap = {
    "4g": window.REBELMOUSE_BOOTSTRAP_DATA.engine.image_quality_level || 80,
    "2g": 60,
    "slow-2g": 60,
    "3g": 75,
};

function getPixelDensity() {
    let devicePixelRatio = window.devicePixelRatio ? parseInt(window.devicePixelRatio, 10) : 1;
    if (undefined !== window.REBELMOUSE_BOOTSTRAP_DATA.engine.override_device_pixel_ratio) {
        devicePixelRatio = parseInt(
            window.REBELMOUSE_BOOTSTRAP_DATA.engine.override_device_pixel_ratio,
            10
        );
    }
    return Math.min(3, Math.max(devicePixelRatio, 1));
}

function getAppropiateQuality() {
    return qualityToConnectionMap[getConnectionType()];
}

function getImageParam(paramName, element, queryParams) {
    let value = queryParams[paramName];
    const param = element.getAttribute(paramName);
    if (param && !isNaN(param)) {
        value = param;
    } else if ("dataset" in element && paramName in element.dataset) {
        value = element.dataset[paramName];
    }
    return value && parseInt(value, 10);
}

function getOptimizedSrc(ogSrc, element, containerWidth, containerHeight) {
    const maxContainerWidth = parseInt(containerWidth, 10);
    const maxContainerHeight = containerHeight ? parseInt(containerHeight, 10) : null;
    const src = new URL(ogSrc);
    const queryParams = translateParamsToObject(src.search);

    const elWidth = getImageParam("width", element, queryParams);
    const elHeight = getImageParam("height", element, queryParams);

    const { maxWidth, maxHeight } = getMaxWidthAndHeight(
        elWidth,
        elHeight,
        maxContainerWidth,
        maxContainerHeight
    );

    const scale = getPixelDensity();
    const params = { ...queryParams, quality: getAppropiateQuality() };

    if ("coordinates" in queryParams && "height" in queryParams) {
        params.coordinates = queryParams.coordinates;
        params.height = parseInt(maxHeight * scale);
    }

    if ("width" in queryParams) {
        params.width = parseInt(maxWidth * scale);
    }

    if (Object.keys(params).length > 1) {
        const queryString = objectToQS(params);
        src.search = queryString;
        return src.href;
    } else {
        return null;
    }
}

const commonParentSelectors = [
    ".rm-container-resized",
    ".load-more-scroll-wrapper",
    ".posts-custom[data-attr-limit]",
    "article",
];

function findAppropiateCommonParentOrFakeIt(element) {
    function doesContainElement(acum, selector) {
        if (acum) {
            return acum;
        }

        return element.closest(selector);
    }

    const parent = commonParentSelectors.reduce(doesContainElement, null);
    return parent || { dataset: {} };
}

function readjustImages(element) {
    const commonParent = findAppropiateCommonParentOrFakeIt(element);
    let optimizedSrc;

    if (commonParent.dataset.reCalcImageWith) {
        optimizedSrc = getOptimizedSrc(
            element.dataset.runnerSrc,
            element,
            commonParent.dataset.reCalcImageWith
        );
    } else {
        const closestContainer = findAppropiateParent(element);

        if (!closestContainer) {
            return;
        }

        const newMaxWidth = closestContainer.dataset.rmCalcWidth || closestContainer.offsetWidth;
        commonParent.dataset.reCalcImageWith = newMaxWidth;

        optimizedSrc = getOptimizedSrc(element.dataset.runnerSrc, element, newMaxWidth);
    }

    if (optimizedSrc) {
        element.dataset.runnerSrc = optimizedSrc;
        element.dataset.adjustedSrc = true;
    }
}

function releasePool(resourceID) {
    return function onLoad() {
        mediaPool.release(resourceID);
    };
}

function lazyImageHandler(entry) {
    function onFreeSlot(resourceID) {
        const node = entry.target;
        if (!node.dataset.adjustedSrc) {
            readjustImages(node);
        }
        const url = node.dataset.runnerSrc;
        const onLoad = releasePool(resourceID);
        node.addEventListener("load", onLoad);
        node.src = url;
        node.removeAttribute("data-runner-src");
        node.removeAttribute("type");
    }

    mediaPool.request(1000).then(onFreeSlot);
}

function backgroundImageHandler(entry) {
    function onFreeSlot(resourceID) {
        const node = entry.target;
        let url = node.dataset.runnerImgHd;

        if (window.REBELMOUSE_BOOTSTRAP_DATA.engine.lazyLoadShortcodeImages) {
            const imageSrc = getOptimizedSrc(url, node, node.offsetWidth, node.offsetHeight);
            if (imageSrc) {
                url = imageSrc;
            }
        }

        const onLoad = releasePool(resourceID);
        const img = new Image();
        img.addEventListener("load", onLoad);
        img.src = url;
        node.style.backgroundImage = `url(${url})`;
    }

    mediaPool.request(1000).then(onFreeSlot);
}

const treshholdCalculator = {
    "4g": function on4G() {
        return 300;
    },
    "3g": function on3G() {
        return 700;
    },
    "2g": function on2G() {
        return 900;
    },
    "slow-2g": function onSlow2G() {
        return 900;
    },
};

export default function loadLazyImages(intersectionObserverProvider) {
    if (window.REBELMOUSE_BOOTSTRAP_DATA.engine.lazyLoadShortcodeImages) {
        createElementChangeListener("img[data-runner-src]", readjustImages);
    }

    createSmartIntersector(intersectionObserverProvider, {
        treshholdCalculator,
        initialThreshold: 50,
        selector: "img[data-runner-src]",
        onIntersect: lazyImageHandler,
    });

    createSmartIntersector(intersectionObserverProvider, {
        treshholdCalculator,
        initialThreshold: 50,
        selector: "[data-runner-img-hd]",
        onIntersect: backgroundImageHandler,
    });
}
